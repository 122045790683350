"use client"

import "@/app/globals.css"
import dynamic from "next/dynamic"

import { Roboto } from "next/font/google"
import { fallbackLocale } from "@/i18n/constants"
import { InitColorSchemeScript } from "ui/dist/styles"

const roboto = Roboto({
  weight: ["300", "400", "500", "700"],
  subsets: ["latin"],
  display: "swap",
  variable: "--font-roboto",
})

const GlobalError = dynamic(() => import("./error"))
const AppProvider = dynamic(() => import("./_providers/AppProvider"))

interface GlobalErrorProps {
  error: Error & { digest?: string }
  reset: () => void
}

export default function GlobalErrorPage({ error, reset }: GlobalErrorProps) {
  const defaultLocale = fallbackLocale

  return (
    <html lang={defaultLocale}>
      <head>
        {/**
         * as of now, loading next/fonts is a known limitation at turbopack, we will use the global font for now
         * https://nextjs.org/docs/messages/no-page-custom-font#possible-ways-to-fix-it
         */}
        <link
          href="https://fonts.googleapis.com/css2?family=Roboto&display=optional"
          rel="stylesheet"
        />
      </head>
      <body
        id="root"
        className={`${roboto.variable} bg-stone-100 dark:bg-stone-900 transition-colors`}
      >
        <InitColorSchemeScript attribute="class" />
        <AppProvider>
          <GlobalError error={error} reset={reset} />
        </AppProvider>
      </body>
    </html>
  )
}
